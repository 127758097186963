
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonSpinner, IonContent, IonCardContent, IonText, IonFabButton, IonCard, IonListHeader  } from '@ionic/vue';
import PointMap from '@/components/atoms/PointMap.vue'
import moment from 'moment'
import DataIconList from '@/components/atoms/DataIconList.vue'
import StateIcon from '@/components/atoms/StateIcon.vue'
import SectionHeader from '@/components/atoms/SectionHeader.vue'

export default defineComponent({
    components: {
        IonPage, SectionHeader, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, PointMap, StateIcon, IonSpinner, IonContent, IonText, IonCardContent, IonFabButton, DataIconList, IonCard, IonListHeader
    },
    props: {
        point: Object,
    },

    setup(){
        const schema = [
            {
                field: 'time',
                icon: 'clock'
            },
            {
                field: 'date',
                icon: 'calendar-day',
                value: (o: any) => {
                    return o.slice(0,10).split('-').join('.')
                }

            },
        ]

        return {
            schema: schema
        }
    }

})

