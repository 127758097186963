import Axios from 'axios'

const axios = Axios.create({
    baseURL: 'http://212.106.6.242:1228'
})


axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("authentication-token");
  if (token) {
    config.headers = {'Authorization': `Bearer ${token}`}
  }
  return config;
});

export default axios