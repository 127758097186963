
import { defineComponent, computed } from 'vue'
import { IonCard, IonCardContent, IonText  } from '@ionic/vue';

export default defineComponent({
    components: {
        IonCard, IonCardContent, IonText
    },

    props: {
        job: Object
    },

    setup(props){
        const job: any = computed(() => props.job)

        return {
        }
    }
})
