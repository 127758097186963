
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import { IonCardContent, IonCard, IonRippleEffect, IonText, IonActionSheet, IonCardTitle  } from '@ionic/vue';
import StateIcon from '@/components/atoms/StateIcon.vue'

export default defineComponent({
    props: {
        alert: Object,
    },

    components: {
        IonCardContent, IonCard, IonRippleEffect, IonText, IonActionSheet, IonCardTitle,
        StateIcon
    },

	setup(props, { emit }){
        // PROPS
        const alert: any = computed(() => props.alert)

        const formatTime = (time: any) => {
            let [d, t] = time.split('T')
            d = d.split('-').join('.')
            t = t.split('+')[0].slice(0,5)
            return t + ', ' + d
        }

        const dialog = ref(false)

        const buttons = [
            {
                text: 'Tak',
                handler: () => {
                    emit('delete', alert.value._id)
                },
            },
            {
                text: 'Nie',
            },
        ]

        return {
            formatTime, dialog, buttons
        }
    }
})
