
import { defineComponent, computed} from 'vue'

export default defineComponent({
    props: {
        size: {
            default: 60
        },
        state: {
            default: 0
        },
    },

    setup(props){
        const icons = ['hourglass-half', 'clipboard-check', 'dolly-flatbed-alt', 'truck-moving', 'dolly-flatbed-empty', 'check-double', 'file-invoice-dollar']
        const colors = ['#546e7a', '#00838f', '#f9a825', '#ef6c00', '#f4511e', '#1b5e20', '#2e7d32']

        const state = computed(() => props.state)
        const type = computed(() => {
            if(state.value < 0) return 0
            if(state.value > 6)return 6
            return state.value
        })
        return {
            colors: colors,
            icons: icons,
            type: type
        }
    }
})
