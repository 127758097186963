
//Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import FontAwesomeIcon from "@/components/atoms/FontAwesome.vue";

import { faDirections } from '@fortawesome/pro-solid-svg-icons'
import { faClock } from '@fortawesome/pro-solid-svg-icons'
import { faCalendarDay } from '@fortawesome/pro-solid-svg-icons'
import { faWeightHanging } from '@fortawesome/pro-solid-svg-icons'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { faEye } from '@fortawesome/pro-solid-svg-icons'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons'


import { faStickyNote } from '@fortawesome/pro-solid-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'


// STANY
import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons'
import { faClipboardCheck } from '@fortawesome/pro-solid-svg-icons'
import { faDollyFlatbedAlt } from '@fortawesome/pro-solid-svg-icons'
import { faTruckMoving } from '@fortawesome/pro-solid-svg-icons'
import { faDollyFlatbedEmpty } from '@fortawesome/pro-solid-svg-icons'
import { faCheckDouble } from '@fortawesome/pro-solid-svg-icons'
import { faFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons'


// JOB CARD
import { faTruck } from '@fortawesome/pro-solid-svg-icons'
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons'
import { faTrailer } from '@fortawesome/pro-solid-svg-icons'



library.add(
    faDirections,
    faClock,
    faEye,
    faCalendarDay,
    faWeightHanging,
    faStickyNote,
    faCheck,
    faAngleRight,
    faTimes,
    faFileDownload,
    faSearch,

    faHourglassHalf,
    faClipboardCheck,
    faDollyFlatbedAlt,
    faTruckMoving,
    faDollyFlatbedEmpty,
    faCheckDouble,
    faFileInvoiceDollar,

    faTruck,
    faAddressCard,
    faTrailer
)

export { FontAwesomeIcon }