
import { defineComponent, computed } from 'vue'
import { IonCard, IonCardContent, IonText, IonRippleEffect } from '@ionic/vue';

export default defineComponent({
    components: {
        IonCard, IonCardContent, IonText, IonRippleEffect
    },

    props: {
        files: Array
    },

    setup(props){
        const files: any = computed(() => props.files)

        return {
        }
    }
})
