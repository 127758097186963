
import { defineComponent, computed } from 'vue'
import { IonCard, IonCardContent, IonText, IonCardTitle  } from '@ionic/vue';
import JobStateIcon from '@/components/atoms/JobStateIcon.vue'

export default defineComponent({
    components: {
        IonCard, IonCardContent, IonText, IonCardTitle, JobStateIcon
    },

    props: {
        job: Object
    },

    setup(props){
        const job: any = computed(() => props.job)

        const titles = ['Niezatwierdzony', 'Zatwierdzony', 'Załadowany', 'W drodze', 'Rozładowany', 'Zakończony', 'Do zafakturowania']

        const state = computed(() => job.value.stan)
        const type = computed(() => {
            if(state.value < 0) return 0
            if(state.value > 6)return 6
            return state.value
        })

        const title = computed(() => titles[type.value])

        return {
            type, title
        }
    }
})
