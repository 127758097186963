
import { defineComponent, ref } from 'vue';
import { IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent } from '@ionic/vue'
import useJobs from '@/store/jobs'
import JobCard from '@/components/atoms/JobCard.vue'

export default defineComponent({
    components: {JobCard, IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent},
    
	async setup(){
		const { getJobs, jobs, refreshJobs } = useJobs()

        await getJobs()
        
        const loadData = async (e: any) => {
            try{
                await getJobs()
                e.target.complete()
            }
            catch(error){
                console.log(error)
            }
        }

        const refresh = async (e: any) => {
            try{
                const minTime = new Promise(r => setTimeout(r, 1000))
                await refreshJobs()
                await minTime
                e.target.complete()
            }
            catch(error){
                console.log(error)
            }
        }

		return {
            getJobs: getJobs,
            jobs: jobs.value,
            loadData: loadData,
            refresh: refresh
		}
	}
});
