
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import JobPoint from '@/components/job-views/points/JobPoint.vue'

export default defineComponent({
    props: {
        job: Object,
    },

    components: {
        JobPoint
    },

	setup(props, { emit }){
        // PROPS
        const job: any = computed(() => props.job)
        const points: any = computed(() =>job.value.points)

        return {
            points: points
        }
    }
})
