
import { defineComponent, ref} from 'vue'
import JobList from '@/components/organisms/JobList.vue'
import JobsFilterModal from '@/components/organisms/JobsFilterModal.vue'
import LoadingFull from '@/components/atoms/LoadingFull.vue'
import AppPage from '@/components/atoms/AppPage.vue'
import { IonButton, IonModal } from '@ionic/vue'

export default defineComponent({
	components: {
		JobList,
		LoadingFull,
		AppPage,
		IonButton,
		JobsFilterModal,
		IonModal
	},

	setup(){
		const dialog = ref(false)
		
		return {
			dialog
		}
	}
});
