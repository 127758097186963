
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import { IonSegment, IonSegmentButton,  } from '@ionic/vue';

export default defineComponent({

    props: {
        modelValue: Number
    },

    components: {
        IonSegment, IonSegmentButton, 
    },

	setup(props, { emit }){

        // DATA
        const model = computed(() => props.modelValue)
        const segment = ref(null)

        const views = ['informacje', 'trasa', 'wiadomosci']
        const activeView = ref('informacje')
        
        const changeView = (i: number) => {
            if(views[i] != activeView.value) activeView.value = views[i]
        }

        // UPDATE INTERNAL STATE
        const viewChanged = (e: any) => {
            activeView.value = e.detail.value
        }

        watch(
            () => activeView.value,
            (val: any) => {
                emit('update:modelValue', views.indexOf(val))
            }
        )

        watch(
            () => model.value,
            (val: any) => changeView(val)
        )
        

        return {
            activeView: activeView,
            viewChanged: viewChanged
        }
	}
});
