
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import JobInfoHero from '@/components/job-views/info/JobInfoHero.vue'
import JobInfoRegular from '@/components/job-views/info/JobInfoRegular.vue'
import JobInfoFiles from '@/components/job-views/info/JobInfoFiles.vue'
import SectionHeader from '@/components/atoms/SectionHeader.vue'

export default defineComponent({
    props: {
        job: Object,
    },

    components: {
        JobInfoHero, JobInfoRegular, JobInfoFiles, SectionHeader
    },

	setup(props, { emit }){
        // PROPS
        const job: any = computed(() => props.job)

        return {
        }
    }
})
