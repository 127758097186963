
import { defineComponent, computed, ref, watchEffect, onMounted } from 'vue'
import useJobs from '@/store/jobs'
import JobSlider from '@/components/job-layout/JobSlider.vue'
import JobSegment from '@/components/job-layout/JobSegment.vue'

export default defineComponent({
    props: {
        name: String
    },

    components: {
        JobSlider, JobSegment
    },

	async setup(props){
        const numerZlecenia = computed(() => props.name)
        const { getJob } = useJobs()
        const job = ref({})

        try{
            job.value = await getJob(numerZlecenia.value)
        }
        catch(error){
            console.log(error)
        }

        const slide = ref(0)

        return {
            slide: slide,
            job: job
        }
	}
});
