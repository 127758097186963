
import { IonPage, IonContent, IonItem, IonLabel, IonButton, IonCardContent, IonSpinner, IonText, IonInput } from '@ionic/vue';
import { defineComponent, ref, reactive } from 'vue';
import useAuth from '@/store/auth'
import { useRouter } from 'vue-router';

export default defineComponent({
	components: {IonPage, IonContent, IonItem, IonLabel, IonButton, IonCardContent, IonSpinner, IonText, IonInput},

	setup(){
		const name = ref('Zlecenia')
		
		const loading = ref(false)
		const error = ref(false)

		const creadentials = reactive({
			"Username": "Paweł", 
			"Password": "pawel2018", 
			"ClientName": "mSpedytor",
			"ClientCode": "12345"
		})

		const { logIn, token } = useAuth()
		const router = useRouter()

		const signIn = async () => {
			error.value = false
			try{
				loading.value = true
				await logIn(creadentials)
				router.push('/zlecenia')
			}
			catch(e){
				error.value = true
				console.log(e)
			}
			loading.value = false
		}

		return {
			name,
			signIn,
			loading,
			error
		}
	}
});
