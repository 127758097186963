
import { defineComponent, computed, ref } from 'vue'
import AppPage from '@/components/atoms/AppPage.vue'
import JobView from '@/components/organisms/JobView.vue'
import LoadingFull from '@/components/atoms/LoadingFull.vue'


import { useRoute } from 'vue-router'

export default defineComponent({
	components: {
        AppPage,
        JobView,
        LoadingFull
	},

	setup(){
        const route = useRoute()
        const name = computed(() => route.params.numer)
        return {
            name: name,
        }
	}
});
