
import { defineComponent, ref } from 'vue'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton } from '@ionic/vue'

export default defineComponent({
    components: {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
        IonToolbar,
        IonButtons,
        IonBackButton
	},
	props: {
        name: String,
        buttons: Boolean
    }
});
