
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import JobAlert from '@/components/job-views/alerts/JobAlert.vue'
import moment from 'moment'
import useJobs from '@/store/jobs'

export default defineComponent({
    props: {
        job: Object,
    },

    components: {
        JobAlert
    },

	setup(props, { emit }){
        // PROPS
        const job: any = computed(() => props.job)
        const alerts: any = computed(() =>{
            return [...job.value.messages, ...job.value.alerts]
                .sort((c: any, p: any) => c.sent < p.sent ? 1 : -1)
        })

        const { markAlertAsRead } = useJobs()

        const markAlertRead = (id: string) => {
            markAlertAsRead(job.value._id, id)
        }

        return {
            alerts: alerts,
            markAlertRead: markAlertRead
        }
    }
})
