
import { defineComponent, computed, ref, watch } from 'vue'
import UseJobs from '@/store/jobs'
import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonSpinner, IonContent, IonCardContent, IonText, IonCard, IonItem, IonSelect, IonSelectOption, IonLabel  } from '@ionic/vue';

export default defineComponent({
    components: {
        IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonSpinner, IonContent, IonText, IonCardContent, IonCard, IonItem, IonSelect, IonSelectOption, IonLabel
    },

    setup(props, {emit}){
        const sort = ref('Id')

        const options = [
            {
                name: 'Id',
                value: '_id',
                query: 'Id'
            },
            {
                name: 'Data podjęcia',
                value: 'dataPodjecia',
                query: 'DateOfPickUpCurrent'
            },
            {
                name: 'Data realizacji',
                value: 'dataRealizacji',
                query: 'DateOfDeliveryCurrent'
            },
            {
                name: 'Stan',
                value: 'stan',
                query: 'State'
            },
        ]
        const { changeSortOptions } = UseJobs()

        const close = async () => {
			try{
                const r = await changeSortOptions(sort.value)
                console.log(r)
                emit('close')
			}
			catch(error){
				console.log(error)
			}
		}

        watch(
            () => sort.value,
            (val: any) => {
                console.log(val)
            }
        )

        return {
            sort, options, close
        }
    }
})

