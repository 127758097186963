import { computed, reactive } from 'vue';
import axios from './axios'


const state = reactive({
    token: null,
    loggedIn: false,
})

const requestAuthenticationToken = async (credentials: any) => {
    try {
        const response = await axios.post("/odata/User/Account/Token", credentials);
        const token = response.data.Token
        sessionStorage.setItem("authentication-token", token);
        state.token = token
        state.loggedIn = true
    }
    catch (error) {
        throw new Error(error.message)
    }
}

export default () => {
    return {
        token: computed(() => state.token),
        logIn: requestAuthenticationToken,
        loggedIn: computed(() => state.loggedIn)
    }
}