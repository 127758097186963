<template lang="pug">
div(
    style="position: relative; display: inline-block"
)
    div(
        style="border-radius: 50%; display: inline-flex; width: 25px; height: 25px; align-items: center; justify-content: center; opacity: 0.2"
        :class="{'mode-inverse-bg': true}"
        :style="{'background-color': color}"
    )
    div(
        style="display: inline-flex; width: 25px; height: 25px; align-items: center; justify-content: center; position: absolute; top: 0; left: 0"
    )
        fa-icon(
            :icon="icon"
            style="width: 10px"
            :color="color"
        )
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        icon: String,
        color: String
    },
})
</script>