
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import { IonText } from '@ionic/vue';
import moment from 'moment'
import StateIcon from '@/components/atoms/StateIcon.vue'
import JobPointModal from '@/components/job-views/points/JobPointModal.vue'

export default defineComponent({
    props: {
        object: Object,
        schema: Array
    },

    components: {
        IonText
    },

	setup(props, { emit }){
        const object: any = computed(() => props.object)

        const getFieldValue = (plan: any) => {
            const out = object.value[plan.field]
            return plan.value ? plan.value(out) : out
        }

        const doesFieldExist = (plan: any) => {
            return !!object.value[plan.field] && (plan.condition ? plan.condition(object.value[plan.field]) : true)
        }

        return {
            getFieldValue: getFieldValue,
            doesFieldExist: doesFieldExist
        }
    }
})
