import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { computed } from 'vue'
import Jobs from '../views/Jobs.vue'
import Job from '../views/Job.vue'
import SignIn from '../views/SignIn.vue'
import useAuth from '@/store/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/sign-in'
  },

  {
    path: '/zlecenia',
    name: 'Zlecenia',
    component: Jobs,
    meta: {
      auth: true
    }
  },
  {
    path: '/zlecenie/:numer',
    name: 'Zlecenie',
    component: Job,
    meta: {
      auth: true
    }
  },
  
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
    meta: {
      auth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const loggedIn = useAuth().loggedIn

router.beforeEach((to, from, next) => { 
  if(!to.meta.auth){ 
      next() 
  }
  
  else {
      if(loggedIn.value){
          next()
      }
      else{
          next({name: 'SignIn'}) 
      }
  }  
})

export default router
