
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import {Loader, LoaderOptions} from 'google-maps'

export default defineComponent({
    props: {
        position: Object,
    },

    setup(props){
        const position: any = computed(() => props.position)
        const display = ref(true)
        if(position.value.lat && position.value.lng) {
            
            const center: any = computed(() => ({
                lat: position.value.lat, 
                lng: position.value.lng
            }))
            
            const loader = new Loader('AIzaSyBJ32kIbBEwRtF47agPNZUrGjr6nv-M1Ok')
            let google: any = null

            const createMap = async (div: any) => {
                try{
                    const map = await new google.maps.Map(div, {
                        center: center.value,
                        zoom: 14,
                        disableDefaultUI: true
                    })

                    const marker = await new google.maps.Marker({
                        position: center.value, 
                        map: map,
                        animation: google.maps.Animation.DROP
                    })
                }
                catch(error){
                    console.log(error)
                }
            }

            onMounted(async () => {
                google = await loader.load()
                await new Promise(r => setTimeout(r, 500))

                const div = document.querySelector('#map')
                await createMap(div)
            })
        }
        else{
            display.value = false
        }

        return {
            display
        }
    }

})
