import axios from './axios'
import { computed, ref, reactive } from 'vue';
import Job from '@/data-structure/job'

let i = 0

const convertZlecenie = (data: any) => {   
    const obj = {
        _id: data.Id,
        canceled: data.Cancel,
        numerZlecenia: data.Number,
        zleceniodawca: data.Principle,
        przewoznik: data.Carrier,
        kierowca: data.Driver1,
        ciagnik: data.Vehicle,
        naczepa: data.Trailer,
        stan: (i % 7), //data.State,
        dataPodjecia: data.DateOfPickUpCurrent,
        dataRealizacji: data.DateOfDeliveryCurrent,
        notatka: '',
        nastepnyPunkt: data.PlaceOfTheNextPoint,
        pliki: [[], [], []],
        kontakty: [],
        trasa: data.Route,
    }
    i++

    // if(data.KIEROWCA1_TELEFON_SLUZBOWY){
    //     obj.kontakty.push({
    //         name: data.KIEROWCA1 ? data.KIEROWCA1 : 'Kierowca',
    //         phone: data.KIEROWCA1_TELEFON_SLUZBOWY,
    //         type: 'kierowca',
    //         note: null
    //     })
    // }

    return obj
}

const convertLoad = (data: any) => {
    return {
        name: data.Name,
        quantity: data.Amount,
        weight: data.GrossWeightKg + 'kg',
        type: data.Package
    }
}

const convertPoint = (data: any) => {
    return {
        _id: data.Id,
        transportId: data.TransportId,
        name: data.Address,
        explicitName: data.AddressName,
        date: data.Date,
        realDate: data.RealDate,
        state: data.State,
        note: 'Testowa notatka',//data.Description,
        type: data.ActionType,
        time: data.Time,
        weight: data.Weight + data.WeightUnit,
        address: {
            lat: data.GEOPOSITION.latitude,
            lng: data.GEOPOSITION.longitude
        },
        load: [
        ]
    }
}

const convertMessage = (data: any) => {
    return {
        _id: data.Id,
        read: data.DateOfRead,
        sent: data.DateOfCreated,
        text: data.Text,
        author: data.Dispatcher,
        type: 'message'
    }
}

const convertAlert = (data: any) => {
    return {
        _id: data.Id,
        read: data.DateOfRead,
        sent: data.DateOfCreated,
        text: data.Text,
        driver: data.Driver,
        type: 'alert'
    }
}

const convertFile = (data: any) => {
    return {
        name: data.FileOriginalName,
        addedBy: data.UserName
    }
}

const sampleAlerts = [
    {
        _id: 'asdasdasd',
        driver: "Transics DEMO",
        read: null,
        sent: "2020-10-04T08:06:23+02:00",
        text: "Alarm GeoFencing Punktu - Pojazd SK207HW wjechał do otoczenia punktu zlecenia (PL) Katowice Zabrska 12.",
        type: "alert",
    }, 
    {
        _id: 'DSadscxas',
        driver: "Transics DEMO",
        read: null,
        sent: "2020-10-05T14:58:54+02:00",
        text: "Alarm niewłaściwego miejsca wykonania zlecenia - Pojazd SK207HW. Kierowca wykonał czynność Ładuje zlecenia nr 662/2020/AA/MD (PL) Katowice Rozdzieńskiego 199 w niewłaściwym miejscu, w odległości 1,3 km w linii prostej od wymaganego miejsca..",
        type: "alert",
    }
]

const state = reactive({
    jobs: [] as any
})

let loaded = 0
let sort = 'Id'

const getJobs = async () => {
    try{
        const response = await axios.get("odata/SimplifiedTransports?$top=15&$skip=" + loaded  + "&$orderby="+ sort +"%20desc");
        loaded = loaded + 15
        console.log(loaded)
        response.data.value
            .map((i: any) => convertZlecenie(i))
            .filter((i: Job) => i.numerZlecenia)
            //.filter((i: Job) => i.numerZlecenia == '662/2020/AA/MD')
            .forEach((i: Job) => state.jobs.push(i))
        

    }   
    catch(error){
        console.log(error)
        throw new Error(error.message)
    }
}

const refreshJobs = async () => {
    loaded = 0
    state.jobs.splice(0)
    
    try{
        await getJobs()
    }
    catch(error){
        throw new Error(error.message)
    }
}

const getJobPoints = async (id: any) => {
    try{
        const pointsQuery = await axios.get("odata/Place?%24filter=(TransportId%20eq%20" + id + ")")
        const points = pointsQuery
            .data.value
            .map((i: any) => convertPoint(i))
    
        for(const point of points){
            const cargoQuery = await axios.get("/odata/LoadsForPlaces?%24filter=(IdPlace%20eq%20" + point._id + ")")
            const cargo = cargoQuery.data.value
                .map((i: any) => convertLoad(i))
                .filter((l: any) => !!l.name)
            cargo.push({
                name: 'Testowy ładunek',
                quantity: 20,
                weight: '20kg',
                type: 'paleta'
            })
            points[points.indexOf(point)].load = cargo
        }
        console.log('PUNKTY', pointsQuery, points)
        return points
    }
   catch(error){
        throw new Error(error.message)
   }
}

const getJobMessages = async (numerZlecenia: any) => {
    try{
        const messagesQuery: any = await axios.get('/odata/Message?%24filter=TransportNumber%20eq%20%27'+ numerZlecenia +'%27%20and%20Kind%20eq%200')
        const messages = messagesQuery.data.value
            .map((i: any) => convertMessage(i))
        
        console.log('WIADOMOŚCI', messages)
        return messages
    }
   catch(error){
        throw new Error(error.message)
   }
}

const getJobFiles = async (numerZlecenia: any) => {
    try{
        const filesQuery: any = await axios.get("/odata/Attachment?%24filter=(TransportId%20eq%20100020719)%20and%20(ForwardingMethodId%20eq%20100000006)")
        const files = filesQuery.data.value
            .map((i: any) => convertFile(i))
        
        console.log('FILES', files)
        return files
    }
   catch(error){
        throw new Error(error.message)
   }
}

const getJobAlerts = async (numerZlecenia: any) => {
    try{
        const alertsQuery: any = await axios.get('/odata/Message?%24filter=TransportNumber%20eq%20%27'+ numerZlecenia +'%27%20and%20Kind%20eq%202')
        console.log(alertsQuery.data.value, 'ASDS')
        const alerts = alertsQuery.data.value
            .map((i: any) => convertAlert(i))
        
        const copy1 = Object.assign({}, sampleAlerts[0])
        const copy2 = Object.assign({}, sampleAlerts[1])

        alerts.push(copy1)
        alerts.push(copy2)
        
        console.log('ALERTY', alerts)
        return alerts
    }
   catch(error){
        throw new Error(error.message)
   }
}


const getJob = async (numerZlecenia: any) => {
    try{
        const job = state.jobs.find((j: any )=> j.numerZlecenia == numerZlecenia)
        const id = job._id
        

        const messages = getJobMessages(numerZlecenia) 
        const alerts = getJobAlerts(numerZlecenia)
        const files = getJobFiles(numerZlecenia)
        const points = getJobPoints(id)

        job.points = await points
        job.messages = await messages
        job.alerts = await alerts
        job.files = await files

        console.log('ZLECENIE', job)
        
        return job
    }
    catch(error){
        throw new Error(error.message)
    }
}


const changeSortOptions = async (s: string) => {
    try{
        loaded = 0
        sort = s
        console.log(loaded, s)
        const r = await refreshJobs()
        return true
    }
    catch(error){
        throw new Error(error.message)
    }
}

const markAlertAsRead = (jobId: string, alertId: string) => {

    state.jobs = state.jobs.map((j: any) => {
        if(j._id == jobId){
            console.log(j, 'TUUU')
            j.alerts = j.alerts.map((a: any) => {
                if(a._id == alertId){
                    a.read = true
                    console.log(a, 'TUU')
                }
                return a
            })
        }
        return j
    })
}

export default () => {
    return {
        getJobs: getJobs,
        getJob: getJob,
        jobs: computed(() => state.jobs),
        refreshJobs: refreshJobs,
        changeSortOptions: changeSortOptions,
        markAlertAsRead: markAlertAsRead
    }
}