
import { defineComponent, reactive, computed } from 'vue'
import { IonCard, IonCardContent, IonCardTitle, IonText, IonTitle, IonRippleEffect } from '@ionic/vue'
import JobStateIcon from '@/components/atoms/JobStateIcon.vue'
import moment from 'moment'

export default defineComponent({
    components: {IonCard, IonCardContent, IonCardTitle, IonText, IonRippleEffect, JobStateIcon},
    
    props: {
        job: Object
    },    

	setup(props: any){ 
        const job = computed(() => props.job)

        const jobInfo = computed(() => {
            let out = ''
            const a = job.value.zleceniodawca
            const b = job.value.przewoznik
            if(a) out = out + a
            if(a && b) out = out + ' / '
            if(b) out = out + b
            return out
        })     

        const jobTime = computed(() => {
            const a = job.value.dataRealizacji
            if(!a) return null
            const dateA = a.split('T')[0].split('-').join('.')
            const timeA = a.split('T')[1].split('+').join(' - ')
            const fullA = dateA + ', ' + timeA

            const b = job.value.dataPodjecia
            if(!b) return null
            const dateB = b.split('T')[0].split('-').join('.')
            const timeB = b.split('T')[1].split('+').join(' - ')
            const fullB = dateB + ', ' + timeB
            
            return fullB + `<br>` + fullA
        })

		return {
            jobInfo: jobInfo,
            jobTime: jobTime
		}
	}
});
