
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import { IonCard, IonCardContent, IonText, IonRippleEffect, IonModal, IonCardTitle  } from '@ionic/vue';
import moment from 'moment'
import StateIcon from '@/components/atoms/StateIcon.vue'
import JobPointModal from '@/components/job-views/points/JobPointModal.vue'
import DataIconList from '@/components/atoms/DataIconList.vue'

export default defineComponent({
    props: {
        point: Object,
    },

    components: {
        IonCard, IonCardContent, IonText, IonRippleEffect, StateIcon, DataIconList, IonModal, JobPointModal, IonCardTitle
    },

	setup(props, { emit }){
        const point: any = computed(() => props.point)
        const schema = [
            {
                field: 'time',
                icon: 'clock'
            },
            {
                field: 'date',
                icon: 'calendar-day',
                value: (o: any) => {
                    return o.slice(0,10).split('-').join('.')
                }

            },
            {
                field: 'weight',
                icon: 'weight-hanging',
                condition: (o: any) => {
                    return o != '0kg'
                }
            },
        ]
        // MODAL
        const dialog = ref(false)

        return {
            schema: schema,
            dialog
        }
    }
})
