
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import { IonSlide, IonSlides, IonSegment, IonSegmentButton,  } from '@ionic/vue';
import JobPointsView from '@/components/job-views/points/JobPointsView.vue'
import JobInfoView from '@/components/job-views/info/JobInfoView.vue'
import JobAlertsView from '@/components/job-views/alerts/JobAlertsView.vue'

export default defineComponent({
    props: {
        job: Object,
        modelValue: Number
    },

    components: {
        IonSlide, IonSlides, IonSegment, IonSegmentButton, 
        JobPointsView, JobInfoView, JobAlertsView
    },

	setup(props, { emit }){
        // PROPS
        const job = ref({})

        // DATA
        const model = computed(() => props.modelValue)
        const activeSlide = ref(0)
        let slider: any = null

        // GET ION-SLIDES
        onMounted(() => {
            slider = document.querySelector('#job-slider')
        })

        // CHANGE INTERNAL STATE
        const changeSlideState = async (...args: number[]) => {
            if(slider) {
                const i: number = args[0]
                if(i) activeSlide.value = i
                else activeSlide.value = await slider.getActiveIndex()
            }
        }

        // MOVE ION-SLIDES
        const moveSlide = async (i: number) => {
            if(slider) {
                await slider.slideTo(i)
                changeSlideState(i)
            }
        }

        // EMIT INTERNAL STATE
        watch(
            () => activeSlide.value,
            (val: any) => {
                emit('update:modelValue', val as number)
            }
        )

        // UPDATE INTERNAL STATE
        watch(
            () => model.value,
            (val: any) => moveSlide(val)
        )

        return {
            changeSlideState: changeSlideState,
        }
	}
});
